<template>
  <div id="app" class="holding-page">
    <div class="logo-section">
      <img src="./assets/FlaskCast-Logo.png" alt="FlaskCast Logo" class="logo" />
    </div>

    <div class="promo-section">
      <p>
        Your ultimate resource for learning Flask, Python's lightweight web framework.
        Tutorials, projects, and more—coming your way soon!
      </p>
    </div>

    <div class="coming-soon-section">
      <h2>Coming Soon</h2>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
    };
  },
  methods: {

  },
};
</script>

<style scoped>
body {
  background-color: #878b96;
}

.holding-page {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
  background-color: #878b96;
  color: #333;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-section .logo {
  max-width: 300px;
}

.promo-section h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #ffffff;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.promo-section p {
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto 30px auto;
  color: #ffffff;
}

.coming-soon-section h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #ffffff;
}

.coming-soon-section p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #ffffff;
}

.coming-soon-section form {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.coming-soon-section input[type="email"] {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.coming-soon-section button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.coming-soon-section button:hover {
  background-color: #0056b3;
}
</style>
